import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ActionType } from '@shared/types/generic.types';
import { ToastComponent } from '@shared/ui/toast/toast.component';
import { toast } from 'ngx-sonner';

export type ToastData = { type?: ActionType; title: string; description?: string };

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  #transloco = inject(TranslocoService);

  primary(title: string, description?: string, i18n?: boolean) {
    this.openToast({ type: 'primary', title, description }, i18n);
  }

  info(title: string, description?: string, i18n?: boolean) {
    this.openToast({ type: 'info', title, description }, i18n);
  }

  positive(title: string, description?: string, i18n?: boolean) {
    this.openToast({ type: 'positive', title, description }, i18n);
  }

  negative(title?: string | undefined, description?: string, i18n?: boolean) {
    this.openToast(
      i18n === undefined || i18n ?
        {
          type: 'negative',
          title: title ?? 'error.generic.title',
          description: description ?? 'error.generic.description',
        }
      : {
          type: 'negative',
          title: title ?? this.#transloco.translate('error.generic.title'),
          description: description ?? this.#transloco.translate('error.generic.description'),
        },
      i18n
    );
  }

  warning(title: string, description?: string, i18n?: boolean) {
    this.openToast({ type: 'warning', title, description }, i18n);
  }

  openToast(data: ToastData, i18n = true) {
    if (i18n) {
      toast.custom(ToastComponent, {
        componentProps: {
          type: data.type,
          title: this.#transloco.translate(data.title),
          description: data.description ? this.#transloco.translate(data.description) : null,
        },
      });
    } else {
      toast.custom(ToastComponent, { componentProps: data });
    }
  }
}
