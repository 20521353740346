import { ActionType, AnchorPoint, AnimationDirection, HorizontalAnchorPoint } from '@shared/types/generic.types';
import { IconName, IconSize } from '../icon/icon.component';

export type ClickMenuConfiguration = {
  anchorPoint?: AnchorPoint | AnchorPoint[];
  animation?: AnimationDirection;
  radioButtons?: boolean;
  dividers?: boolean;
};

export type ClickMenuItem = {
  id: string;
  type?: 'button' | 'info' | 'user';
  title?: string;
  subtitle?: string;
  icon?: IconName;
  iconSize?: IconSize;
  iconPosition?: HorizontalAnchorPoint;
  image?: string;
  active?: boolean;
  color?: ActionType;
  action?: () => void;
  disabled?: boolean;
};

export type ClickMenuItemGroup = {
  id: string;
  title?: string;
  items: ClickMenuItem[];
};

export const CLICK_MENU_DEFAULT_CONFIGURATION: ClickMenuConfiguration = {
  anchorPoint: ['bottom', 'right'],
  animation: 'from-top',
};
